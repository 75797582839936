'use strict'

const {fedops, bi, ACTION_NAMES} = require('../utils/loggingUtils')
const scriptsHandler = require('./scriptHandler')
const _ = require('lodash')

const dateWrapperKey = 'WixCodeDate'

function importScriptsAsNpmModule(workerGlobalScope, url, appId, scriptName, scriptScope = workerGlobalScope) {
    const module = workerGlobalScope.module = {}
    workerGlobalScope.module.exports = {}
    workerGlobalScope.exports = workerGlobalScope.module.exports
    const oldRequire = workerGlobalScope.require
    workerGlobalScope.require = function require(mod) {
    //enable use of require('lodash') for external bundles
        if (mod === 'lodash') {
            mod = '_'
        }
        if (!workerGlobalScope[mod]) {
            console.error(`Failed to require module: ${mod} from url: ${url}`) //eslint-disable-line no-console
        }
        return workerGlobalScope[mod]
    }

    const beforeLoad = Date.now()
    try {
        scriptScope.importScripts(url, appId, scriptName, beforeLoad)
    } catch (err) {
        bi.reportPlatformRenderError({
            name: ACTION_NAMES.SCRIPT_LOAD_FAILED,
            appId,
            details: JSON.stringify({
                scriptName,
                scriptUrl: url
            }),
            duration: Date.now() - beforeLoad,
            error: err.message
        })
        console.error(`Failed to import script: ${scriptName}, url: ${url}`) //eslint-disable-line no-console
    }
    delete workerGlobalScope.module
    delete workerGlobalScope.exports
    workerGlobalScope.require = oldRequire
    return module.exports
}

function importScriptsAsAmdModule(workerGlobalScope, appId, url, scriptName) {
    let definedModule = null
    const oldDefine = workerGlobalScope.define
    workerGlobalScope.define = function define(deps, mod) {
        definedModule = mod
    }
    workerGlobalScope.define.amd = true
    const {reportInteractionStarted, reportInteractionEnded} = fedops.getInteractionReportFunctions({name: ACTION_NAMES.SCRIPT_LOADED, details: scriptName, params: {appId}})
    reportInteractionStarted()
    const beforeLoad = Date.now()
    try {
        workerGlobalScope.importScripts(url)
        reportInteractionEnded({duration: _.now() - beforeLoad})
    } catch (err) {
        const errorMsg = err && err.message
        bi.reportPlatformRenderError({
            name: ACTION_NAMES.SCRIPT_LOAD_FAILED,
            appId,
            details: JSON.stringify({
                scriptName,
                scriptUrl: url
            }),
            duration: Date.now() - beforeLoad,
            error: errorMsg
        })
        /*eslint-disable no-console*/
        console.error(`Failed to import script: ${scriptName}, url: ${url}`)
        return function () { return {} }
    }

    if (oldDefine) {
        workerGlobalScope.define = oldDefine
    } else {
        delete workerGlobalScope.define
    }

    return definedModule
}

function encodeDates(data) {
    if (!data) {
        return data
    }
    return JSON.parse(JSON.stringify(data, dateReplacer))
}

function dateReplacer(key, val) {
    if (this[key] instanceof Date) {
        return {[dateWrapperKey]: val}
    }
    return val
}

function decodeDates(data) {
    if (!data) {
        return data
    }
    const dataStr = JSON.stringify(data)
    return JSON.parse(dataStr, dateReviver)
}

function dateReviver(key, value) {
    if (value && typeof value === 'object' && value.hasOwnProperty(dateWrapperKey)) {
        return new Date(value.WixCodeDate)
    }
    return value
}

function importModules(modules, appsStore) {
    modules.forEach(appModule => {
        fedops.reportAppLoadStarted({appId: appModule.id})
        const app = importScriptsAsNpmModule(self, appModule.url, appModule.id, appModule.name || appModule.displayName, scriptsHandler)
        storeApps(appsStore, appModule, app)
        _.forEach(appModule.controllerScriptMap, (url, controllerId) => {
            const controllerModule = importScriptsAsNpmModule(self, url, controllerId, appModule.name || appModule.displayName, scriptsHandler)
            appsStore.updateControllerScriptMap(appModule.id, controllerId, controllerModule)
        })
    })
}

const throwError = err => {
    throw err
}

function storeApps(appsStore, module, app) {
    appsStore.set(module.id, {
        module: app,
        applicationId: module.applicationId,
        name: module.name || module.displayName,
        controllerScriptMap: module.controllerScriptMap,
        appDefId: module.id,
        controllers: {}
    })
    if (!module.applicationId && module.appInnerId) {
        appsStore.mapInnerId(module.appInnerId, module.id)
    }
}

function measurePerformanceStart(phase) {
    if (!self.performance || !_.isFunction(self.performance.mark)) {
        return
    }
    self.performance.mark(`${phase}_start`)
}

function measurePerformanceEnd(phase) {
    if (!self.performance || !_.isFunction(self.performance.mark) || !_.isFunction(self.performance.measure)) {
        return
    }
    self.performance.mark(`${phase}_end`)
    self.performance.measure(
        phase,
        `${phase}_start`,
        `${phase}_end`
    )
}


module.exports = {
    importScriptsAsNpmModule,
    importScriptsAsAmdModule,
    importModules,
    encodeDates,
    decodeDates,
    throwError,
    storeApps,
    measurePerformanceEnd,
    measurePerformanceStart
}
